import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Roboto', sans-serif",
        heading: "'Nunito', sans-serif",
        monospace: "'Roboto', sans-serif",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#005f8c',
        background: '#fff',
        grey: '#4e7a8e',
        primary: '#3abffe', // 32f900
        secondary: '#ef00ff', // 00ffc4
        muted: '#f0faff',
    },
}